<template>
  <v-container class="fill-height" >
    <v-row dense align="center">
      <v-col class="text-center" >
        <h1 class="mb-12">Thanks for the feedback!!!</h1>
        <img src="https://vblimages.blob.core.windows.net/images/VBL-Logo-Stamp.jpg" alt="" class="tilted">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.tilted {
  transform: rotate(-9deg);
}
</style>
